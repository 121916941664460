@use "variables" as *;

h1, h2, h3,
h4, h5, h6 {
    font-family: $font-headline;
    font-weight: 500;
}

body, p {
    font-family: $font-base;
}

@each $color-title, $color-value in $theme-colors {
    .color\:#{$color-title} {
        color: #{$color-value};
    }
}

.scrolltext .word {
    opacity: .05;
}