$font-base: "Inter Mono";
$font-headline: "Inter";


$primary: #000;
$secondary: #FD3478;
$light: #A2A2A2;
$white: #fff;

$theme-colors: (
    "primary": $primary,
    "secondary": $secondary,
    "light": $light,
    "white": $white
) !default;