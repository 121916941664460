@use "variables" as *;
@use "typography";
@use "backgrounds";

body {
    background: $primary;
    color: $white;
}

h1 {
    color: $white;
    strong {
        color: $secondary;
    }
}

a.button {
    margin-top: 2rem;
    margin-left: auto;
    margin-right: auto;
    display: inline-block;
    background-color: $secondary;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 500;
    padding: 1rem;
    border-radius: 10000rem;
}